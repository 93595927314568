const baseUrl = 'assets/uds_icons/';

export const SvgMap = {
  HeaderUser: `${baseUrl}HeaderUser.svg`,
  helpCenter: `${baseUrl}helpCenter.svg`,
  notification: `${baseUrl}notification.svg`,
  invoices: `${baseUrl}invoices.svg`,
  advanceConsent: `${baseUrl}advance-consent.svg`,
  logout: `${baseUrl}logout.svg`,
  manage_users: `${baseUrl}manage_users.svg`,
  user_settings: `${baseUrl}user_settings.svg`,
  company_settings: `${baseUrl}company_settings.svg`,
  support: `${baseUrl}online_chat.svg`,
  contact: `${baseUrl}contact.svg`,
  tutorial: `${baseUrl}tutorial.svg`,
  info: `${baseUrl}info.svg`,
  infoIcon: `${baseUrl}info-icon.svg`,
  // header.component
  addTag: `${baseUrl}addTag.svg`,
  flag: `${baseUrl}flag.svg`,
  flagFilled: `${baseUrl}flagFilled.svg`,
  share_person: `${baseUrl}share_person.png`,
  document: `${baseUrl}document.svg`,
  note: `${baseUrl}note.svg`,
  product: `${baseUrl}product.svg`,
  productMaster: `${baseUrl}product_master.svg`,
  report: `${baseUrl}report.svg`,
  exception: `${baseUrl}exception.svg`,
  exceptionWarning: `${baseUrl}exception-red.svg`,
  exceptionIcon: `${baseUrl}exceptionIcon.svg`,
  exceptionIcon2: `${baseUrl}exceptionIcon2.svg`,
  pod: `${baseUrl}pod.svg`,
  edit: `${baseUrl}edit.svg`,
  search: `${baseUrl}search.svg`,
  search_blue: `${baseUrl}search_blue.svg`,
  export: `${baseUrl}export.svg`,
  notFoundContainers: `${baseUrl}notFoundContainers.svg`,
  noResultMileStones: `${baseUrl}noResultMileStones.png`,
  noResultSchedules: `${baseUrl}noResultSchedules.png`,
  noResultExceptions: `${baseUrl}noResultExceptions.png`,
  noNotifications: `${baseUrl}noNotifications.png`,
  noResultTbgLeft: `${baseUrl}noResultTbgLeft.png`,
  noOrderProductsFound: `${baseUrl}noOrderProductsFound.png`,
  noOrderShipmentsFound: `${baseUrl}noOrderShipmentsFound.png`,
  noCalendarFound: `${baseUrl}noCalendarFound.png`,
  noCalendarFoundHR: `${baseUrl}noCalendarFoundHighResolution.png`,
  noExceptionsFound: `${baseUrl}noCalendarFound.png`,
  noMilestonesFound: `${baseUrl}noMilestonesFound.png`,
  oceanOnDashboard: `${baseUrl}oceanOnDashboard.png`,
  airOnDashboard: `${baseUrl}airOnDashboard.png`,
  noResultTbg: `${baseUrl}noResultTbg.png`,
  noExceptions: `${baseUrl}noExceptions.svg`,
  noResultFound: `${baseUrl}no_result_found.svg`,
  addContainer: `${baseUrl}addContainer.svg`,
  history: `${baseUrl}history.svg`,
  upload: `${baseUrl}upload.svg`,
  open_eye: `${baseUrl}open_eye.svg`,
  open_eye_gray: `${baseUrl}open_eye_gray.svg`,
  filter: `${baseUrl}filter.svg`,
  filter1: `${baseUrl}filter1.svg`,
  filterActive: `${baseUrl}filterActive.svg`,
  delete: `${baseUrl}delete.svg`,
  complete: `${baseUrl}complete.svg`,
  inComplete: `${baseUrl}inComplete.svg`,
  shareLink: `${baseUrl}shareLink.svg`,
  arrowDown: `${baseUrl}arrowDown.svg`,
  arrowUp: `${baseUrl}arrowUp.svg`,
  airFreight: `${baseUrl}airFreight.svg`,
  uds_delete: `${baseUrl}uds_delete.svg`,
  f_tracking: `${baseUrl}f_tracking.svg`,
  f_supplyChain: `${baseUrl}f_supplyChain.svg`,
  f_exception: `${baseUrl}f_exception.svg`,
  f_insight: `${baseUrl}f_insight.svg`,
  demurrage_configuration: `${baseUrl}demurrage-configuration.svg`,
  airShipment: `${baseUrl}air-shipments.svg`,
  olShipment: `${baseUrl}Outsourced_logistic_icon.svg`,
  roadShipment: `${baseUrl}road-1.svg`,
  flight: `${baseUrl}flight.svg`,
  vessel: `${baseUrl}vessel.svg`,
  co2: `${baseUrl}co2.svg`,
  blueTick: `${baseUrl}blueTickIcon.svg`,
  whiteTick: `${baseUrl}whiteTickIcon.svg`,
  closeIcon: `${baseUrl}close-icon.svg`,
  closeIcon2: `${baseUrl}close-icon2.svg`,
  dragIcon: `${baseUrl}drag.svg`,
  shipments: `${baseUrl}oceanSideNav.svg`,
  air_shipments: `${baseUrl}air-shipment-2.svg`,
  truck: `${baseUrl}road-1.svg`,
  expand: `${baseUrl}expand.svg`,
  expandRev: `${baseUrl}expand-rev.svg`,
  predictedEta: `${baseUrl}predicted_eta.svg`,
  arrivingPredictedEta: `${baseUrl}arriving_predicted_eta.svg`,
  infoExclamation: `${baseUrl}info-exclamation.svg`,
  carouselLeft: `${baseUrl}carousel-left.svg`,
  carouselRight: `${baseUrl}carousel-right.svg`,
  closeIconFilter: `${baseUrl}close_icon.svg`,
  demdetGraph: `${baseUrl}demdetGraph.svg`,
  demdetTable: `${baseUrl}demdetTable.svg`,
  config_icon: `${baseUrl}config_icon.svg`,
  graph_loader: `${baseUrl}graph_loader.svg`,
  no_graph_data: `${baseUrl}no_graph_data.svg`,
  customisation_icon: `${baseUrl}customisation_icon.svg`,
  port_vessel_change: `${baseUrl}port_vessel_change.svg`,
  checkConsentStatus: `${baseUrl}checkConsentStatus.svg`,
  vehicleupdate: `${baseUrl}vehicleupdate.svg`,
  vehiclenotupdate: `${baseUrl}vehiclenotupdate.svg`,
  warningvehicle: `${baseUrl}warningvehicle.svg`,
  milestone: `${baseUrl}milestone.svg`,
  time: `${baseUrl}time.svg`,
  user_1: `${baseUrl}user-1.svg`,
  user_3: `${baseUrl}user-3.svg`,
  noNewsResult: `${baseUrl}no_news_result.svg`,
  interModalJourney: `${baseUrl}intermodal-journey.svg`,
  locations2: `${baseUrl}locations.svg`,
};
