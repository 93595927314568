import { User, CreateEmailStatus, LoginCompanyStatusType, ACCOUNT_TYPES } from './user.model';
import { AuthData, ReGenerateOrganizationToken } from './auth-data.model';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map, mergeMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { Utility } from '@dp/utilities';
import { CookieService } from 'ngx-cookie-service';
import { Organization, OrganizationMarketingPreference } from 'app/settings/users/users.model';
import { Location } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  DeleteConfiguration,
  DemurrageConfiguration,
  DemurrageConfigurations,
  NotificationMetric,
} from 'app/shared/components/demurrage-configuration/demurrage-configuration.model';
import { UserAccessService } from './user-access.service';
import { BoardConfigService } from 'app/board/board-config.service';
import { ThemeService } from 'app/theme/theme.service';

@Injectable()
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>(null);
  private currentOrganizationSubject = new BehaviorSubject<Organization>(null);
  public readonly currentUser: Observable<User> = this.currentUserSubject.asObservable();
  public token: string;
  private userLocale: string;
  private organizationUrl = environment.rootUrl + environment.urls.organization;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private location: Location,
    private gaService: GoogleAnalyticsService,
    private userAccessService: UserAccessService,
    private boardConfigService: BoardConfigService,
    private themeService: ThemeService
  ) {
    this.clearCookies();
    let user = Utility.getItemDecrypted(environment.storage.currentUser) || ({} as User);
    // if (!user && environment.hasOwnProperty('mockUser')) {
    //   // tslint:disable-next-line: no-string-literal
    //   user = environment['mockUser'] as User;
    //   Utility.setItemEncrypted(environment.storage.currentUser, user);
    // }
    this.handleNewUser(user);
    let org = Utility.getItemDecrypted(environment.storage.currentOrg) || ({} as Organization);
    this.handleNewOrganization(org);
  }

  clearCookies() {
    //todo: this is a fix to clear cookies for users who have old cookies. Will remove this code at Jan 2024.
    this.cookieService.delete('X-DPW-User-Token');
    this.cookieService.delete('X-DPW-ApiKey');
  }

  handleNewUser(user: User) {
    if (user) {
      //moment.locale(user.locale);
      moment.defaultFormat = user.datePattern;
      this.patchTruckColumns(user);
    }
    if (this.themeService.userId !== user.id) {
      this.themeService.changeTheme(user.themeColors, user.id);
    }
    this.currentUserSubject.next(user);
  }
  handleNewOrganization(organization: Organization) {
    this.currentOrganizationSubject.next(organization);
  }

  patchTruckColumns(user: User) {
    //we will check this for all old users
    const columns = user?.truckShipmentColumnPreferences?.[0].columns;
    if (columns && columns.length > 0) {
      const truckNoIndex = columns.findIndex((col) => col.key === 'truckNumber');
      const hasConsentStatus = columns.find((col) => col.key === 'consentStatus');
      if (!hasConsentStatus) {
        columns.splice(truckNoIndex + 1, 0, {
          key: 'consentStatus',
          isEnabled: true,
          allowedChange: true,
        });
      }
      const hasDriverPhone = columns.find((col) => col.key === 'driverPhone');
      if (!hasDriverPhone) {
        columns.splice(truckNoIndex + 1, 0, {
          key: 'driverPhone',
          isEnabled: true,
          allowedChange: true,
        });
      }
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get currentOrganizationValue(): Organization {
    return this.currentOrganizationSubject.value;
  }
  public get showCargoesLogisticsMarketing(): boolean {
    return this.currentOrganizationValue?.accountPreferences?.INTERMODALSHIPMENT?.find((pref) =>
      pref.columnName.includes('showCargoesLogisticsMarketing')
    )?.isEnabled;
  }

  public get isTeamFeatureEnabled(): boolean {
    return [ACCOUNT_TYPES.TRIAL, ACCOUNT_TYPES.PREMIUM].includes(this.currentOrganizationValue?.accountType);
  }

  registerUser(authData: AuthData) {
    return this.http
      .post(
        environment.rootUrl + environment.urls.register,
        {},
        {
          headers: new HttpHeaders({
            // [environment.apiKey]: environment.apiValue,
            Authorization: 'Basic ' + btoa(authData.email + ':' + authData.password),
          }),
          observe: 'response',
        }
      )
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          return user;
        })
      );
  }

  loginAfterVerify(authData: AuthData): Observable<HttpResponse<User>> {
    return this.http.post<User>(
      environment.rootUrl + environment.urls.login,
      {},
      {
        headers: new HttpHeaders({
          [environment.apiKey]: environment.apiValue,
          Authorization: 'Basic ' + btoa(authData.email + ':' + authData.password),
        }),
        observe: 'response',
      }
    );
  }

  ssoLoginUser(params: any): Observable<HttpResponse<User>> {
    return this.http
      .post(environment.rootUrl + environment.urls.login, params, {
        headers: new HttpHeaders({
          [environment.apiKey]: environment.apiValue,
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<User>) => {
          const user = response.body as User;
          //todo: get rid of mock up code
          if (environment.enableBeta) {
            let columns = user.containerShipmentColumnPreferences.find((pref) => pref.key === 'keyColumns').columns;
            if (!columns.find((col) => col.key === 'subscribe')) {
              columns.push({
                key: 'subscribe',
                isEnabled: true,
                allowedChange: false,
              });
            }
            let truckColumns = user.truckShipmentColumnPreferences[0].columns;
            if (!truckColumns.find((col) => col.key === 'subscribe')) {
              truckColumns.splice(2, 0, {
                key: 'subscribe',
                isEnabled: true,
                allowedChange: false,
              });
            }
          }
          //todo: end
          return user;
        })
      );
  }

  login(authData: AuthData): Observable<HttpResponse<User>> {
    return this.http
      .post(
        environment.rootUrl + environment.urls.login,
        {},
        {
          headers: new HttpHeaders({
            [environment.apiKey]: environment.apiValue,
            Authorization: 'Basic ' + btoa(authData.email + ':' + authData.password),
          }),
          observe: 'response',
        }
      )
      .pipe(
        tap((response: HttpResponse<User>) => {
          const user = response.body as User;
          this.setupNewUser(user, response); //this would set the header X-DPW-User-Token for further http traffic
        }),
        mergeMap(
          () => this.getOrganization(),
          (v1, v2) => {
            const user = v1.body as User;
            user.accountType = v2.accountType;
            user.accountCapabilities = v2.accountCapabilities;
            user.organizationPartnerships = v2.organizationPartnerships;
            this.setupNewUser(user, v1); //setupNewUser is called again here due to the getOrganization call. Will make this simple in the api code to get user with org info and make code much cleaner here.
            this.setupNewOrganization(v2);
            return v1;
          }
        )
      );
  }

  getOrganization(): Observable<Organization> {
    return this.http.get<Organization>(this.organizationUrl);
  }

  updateOrganizationMarketingPreference(payload): Observable<OrganizationMarketingPreference> {
    return this.http.put<OrganizationMarketingPreference>(environment.rootUrl + 'admin/updateOrganizationAccountDetails', payload);
  }

  reGenerateOrganizationToken(): Observable<ReGenerateOrganizationToken> {
    return this.http.get<ReGenerateOrganizationToken>(this.organizationUrl + '/generateOrganizationToken');
  }

  getOrgLogo(orgId: string) {
    return this.http.get(environment.rootUrl + `${environment.urls.orgLogo}/${orgId}`, { responseType: 'blob' });
  }

  deleteOrgLogo(orgId: string) {
    return this.http.delete(environment.rootUrl + `${environment.urls.orgLogo}/${orgId}`);
  }

  uploadOrgLogo(reqBody) {
    return this.http.post(environment.rootUrl + environment.urls.orgLogo, reqBody);
  }

  public setupNewUser(user: User, response: HttpResponse<User>) {
    this.setupNewUserDirect(user, response.headers.get(environment.HEADER.X_USER_TOKEN));
  }

  public setupNewOrganization(organization: Organization) {
    Utility.setItemEncrypted(environment.storage.currentOrg, organization);
    this.handleNewOrganization(organization);
  }

  public setupNewUserDirect(user: User, token: string) {
    this.resetSessionData();
    user.fullName = Utility.getFullName(user);
    user.token = token;

    let newUser = { ...this.currentUserValue, ...user };

    Utility.setItemEncrypted(environment.storage.currentUser, newUser);
    user?.userPermissions && this.storePermissionData(user.userPermissions);
    user?.dashboardConfigurations && this.storeBoardConfigData(user.dashboardConfigurations);
    this.handleNewUser(newUser);
  }

  public storePermissionData(data) {
    this.userAccessService.updatePermissibleData(data);
  }

  storeBoardConfigData(data) {
    this.boardConfigService.updateBoardConfigData(data, true);
  }

  setupNewUserFromStorage() {
    let user = JSON.parse(localStorage.getItem(environment.storage.superAdminUser)) as User;
    this.resetSessionData();
    Utility.setItemEncrypted(environment.storage.currentUser, user);
    user?.userPermissions && this.storePermissionData(user.userPermissions);
    user?.dashboardConfigurations && this.storeBoardConfigData(user.dashboardConfigurations);
    localStorage.removeItem(environment.storage.superAdminUser);
    this.handleNewUser(user);
  }

  setupNewOrganizationFromStorage() {
    let org = JSON.parse(localStorage.getItem(environment.storage.superAdminOrg)) as Organization;
    Utility.setItemEncrypted(environment.storage.currentOrg, org);
    localStorage.removeItem(environment.storage.superAdminUser);
    this.handleNewOrganization(org);
  }

  loginWithToken(token: string, fromSuperAdmin: boolean = null): Observable<User> {
    return this.http
      .get(environment.rootUrl + environment.urls.loginWithToken, {
        headers: new HttpHeaders({
          [environment.apiKey]: environment.apiValue,
          [environment.HEADER.X_USER_TOKEN]: token,
          [environment.HEADER.IMPERSONATED_TOKEN]: 'true',
        }),
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          if (fromSuperAdmin === true) {
            let result = Utility.getItemDecrypted(environment.storage.currentUser);
            localStorage.setItem(environment.storage.superAdminUser, JSON.stringify(result));
          }
          user.fullName = Utility.getFullName(user);
          user.token = response.headers.get(environment.HEADER.X_USER_TOKEN);
          Utility.setItemEncrypted(environment.storage.currentUser, user);
          this.resetSessionData();
          //this.currentUserSubject.next(user);
          this.handleNewUser(user);
          return user;
        })
      );
  }

  public resetSessionData() {
    //reset new session data
    localStorage.removeItem(environment.storage.currentSession);
  }

  // new company case
  signupCompany(payload: Object): Observable<Object> {
    return of({
      result: 'success',
      company: {
        id: 1,
      },
    });
  }
  joinCompany(payload: Object): Observable<Object> {
    return of({});
  }

  fromSuperAdmin() {
    return localStorage.getItem(environment.storage.superAdminUser);
  }

  normalUserOnNormalPage(): boolean {
    return !this.anonymousMode() && this.isAuth() && !this.fromSuperAdmin();
  }
  private anonymousMode() {
    const anonymousUrls = ['_tracking_shipment?', '_tracking?'];
    return anonymousUrls.find((url) => this.location.path().indexOf(url) > -1) ? true : false;
  }

  private authSuccessfully() {
    // this.authChange.next(true);
    this.router.navigate(['/welcome']);
  }

  userUpdated(user: User) {
    const newUser = { ...this.currentUserValue, ...user };
    // console.log('old user', this.currentUserValue, 'new user', newUser);
    Utility.setItemEncrypted(environment.storage.currentUser, newUser);
    this.handleNewUser(newUser);
  }

  logout() {
    localStorage.removeItem(environment.storage.currentUser);
    localStorage.removeItem(environment.storage.currentSession);
    localStorage.removeItem(environment.storage.superAdminUser);
    localStorage.removeItem(environment.storage.selectedBenchmarks);
    Utility.removeItem(environment.storage.showAddCarrierStrip, 'session');
    Utility.removeItem(environment.storage.sidebarCollapse, 'session');
    Utility.removeItem(environment.storage.oceanSwitchViewState, 'session');
    Utility.removeItem(environment.storage.showFeedbackBanner, 'session');

    this.cookieService.deleteAll();

    this.currentUserSubject.next(null);
    this.router.navigate(['/welcome']);
  }

  createEmail(email: string, recaptcha: string, source: string, googleAnalyticsInfo: any): Observable<CreateEmailStatus> {
    let data: Object = { userEmail: email, gToken: recaptcha };
    if (source) {
      data = { ...data, source: source };
    }
    if (googleAnalyticsInfo) {
      data = { ...data, googleAnalyticsInfo };
    }
    return this.http.post<CreateEmailStatus>(environment.rootUrl + environment.urls.register_email, data);
  }

  // resendEmail(email: string): Observable<CreateEmailStatus> {
  //   //mock
  //   return of(true);
  // }

  resetPasswordEmail(email: string): Observable<Object> {
    return this.http.post<Object>(environment.rootUrl + environment.urls.forgot_password, {
      userEmail: email,
    });
  }
  resetPassword(payload: Object): Observable<Object> {
    return this.http.post<Object>(environment.rootUrl + environment.urls.forgot_password, payload);
  }

  changeEmailConfirm(verificationCode: string): Observable<Object> {
    const payload = { verificationCode };
    return this.http.post<Object>(environment.rootUrl + environment.urls.changeEmailConfirm, payload, {
      headers: { 'Content-Type': 'application/json' },
      observe: 'response',
    });
  }

  sa_getUserToken(payload): Observable<Object> {
    return this.http.post<Object>(environment.rootUrl + environment.urls.sa_getUserToken, payload);
  }

  isAuth() {
    return this.currentUserValue !== null && this.currentUserValue.companyStatus === LoginCompanyStatusType.VERIFIED;
  }

  isAdmin() {
    return this.currentUserValue && this.currentUserValue.isAdmin;
  }

  onSubmit(form: NgForm) {
    //console.log(form);
  }

  getUserLocale() {
    if (this.userLocale) {
      return this.userLocale;
    }

    let user = this.currentUserValue;
    let allowedLocales = ['en-US', 'en-GB', 'en-CA', 'en-AU', 'zh-CN'];
    if (user && user.locale) {
      if (allowedLocales.indexOf(user.locale) !== -1) {
        this.userLocale = user.locale;
        return user.locale;
      } else {
        console.warn('User Locale: ', user.locale);
      }
    }
    return 'en-US';
  }

  showSubscription(): boolean {
    return (
      this.currentUserValue &&
      this.currentUserValue.enableShipmentNotifications &&
      this.currentUserValue.enableCustomNotifications &&
      this.currentUserValue.enableNotificationsForSubscribedShipments
    );
  }

  backToSA() {
    this.setupNewUserFromStorage();
    this.setupNewOrganizationFromStorage();
  }

  getCookie(name: string): string {
    if (document.cookie) {
      let ca: Array<string> = document.cookie.split(';');
      let caLen: number = ca.length;
      let cookieName = `${name}=`;
      let c: string;
      for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
          return c.substring(cookieName.length, c.length);
        }
      }
    }
    return '';
  }

  getGoogleAnalyticsInfo(): any {
    const ga_connector_info = {};
    const gId = this.getCookie('_gid');
    const trafficSources = this.getCookie('gaconnector_all_traffic_sources');
    const gaClientId = this.getCookie('gaconnector_GA_Client_ID');
    const pageVisited = this.getCookie('gaconnector_pages_visited_list');
    const browser = this.getCookie('gaconnector_browser');
    const cityFromIpAddress = this.getCookie('gaconnector_city');
    const countryFromIpAddress = this.getCookie('gaconnector_country');
    const region = this.getCookie('gaconnector_region');
    const device = this.getCookie('gaconnector_device');
    const firstClickCampaign = this.getCookie('gaconnector_fc_campaign');
    const firstClickChannel = this.getCookie('gaconnector_fc_channel');
    const firstClickContent = this.getCookie('gaconnector_fc_content');
    const firstClickLandingPage = this.getCookie('gaconnector_fc_landing');
    const firstClickMedium = this.getCookie('gaconnector_fc_medium');
    const firstClickReferrer = this.getCookie('gaconnector_fc_referrer');
    const firstClickSource = this.getCookie('gaconnector_fc_source');
    const firstClickTerm = this.getCookie('gaconnector_fc_term');
    const lastClickCampaign = this.getCookie('gaconnector_lc_campaign');
    const lastClickChannel = this.getCookie('gaconnector_lc_channel');
    const lastClickContent = this.getCookie('gaconnector_lc_content');
    const lastClickLandingPage = this.getCookie('gaconnector_lc_landing');
    const lastClickMedium = this.getCookie('gaconnector_lc_medium');
    const lastClickReferrer = this.getCookie('gaconnector_lc_referrer');
    const lastClickSource = this.getCookie('gaconnector_lc_source');
    const lastClickTerm = this.getCookie('gaconnector_lc_term');
    const gclid = this.getCookie('gaconnector_gclid');
    const ipAddress = this.getCookie('gaconnector_ip_address');
    const latitude = this.getCookie('gaconnector_latitude');
    const longitude = this.getCookie('gaconnector_longitude');
    const operatingSystem = this.getCookie('gaconnector_OS');
    const gasessionid = this.getCookie('gaconnector_GA_Session_ID');
    if (gId) {
      ga_connector_info['gaconnectorid'] = gId;
    }
    if (trafficSources) {
      ga_connector_info['alltrafficsources'] = trafficSources;
    }
    if (gaClientId) {
      ga_connector_info['googleanalyticscid'] = gaClientId;
    }
    if (pageVisited) {
      ga_connector_info['pagesvisited'] = pageVisited;
    }
    if (browser) {
      ga_connector_info['browser'] = browser;
    }
    if (cityFromIpAddress) {
      ga_connector_info['cityfromipaddress'] = cityFromIpAddress;
    }
    if (countryFromIpAddress) {
      ga_connector_info['countryfromipaddress'] = countryFromIpAddress;
    }
    if (region) {
      ga_connector_info['region'] = region;
    }
    if (device) {
      ga_connector_info['device'] = device;
    }
    if (firstClickCampaign) {
      ga_connector_info['firstclickcampaign'] = firstClickCampaign;
    }
    if (firstClickChannel) {
      ga_connector_info['firstclickchannel'] = firstClickChannel;
    }
    if (firstClickContent) {
      ga_connector_info['firstclickcontent'] = Utility.removeHttp(firstClickContent);
    }
    if (firstClickLandingPage) {
      ga_connector_info['firstclicklandingpage'] = Utility.removeHttp(firstClickLandingPage);
    }
    if (firstClickMedium) {
      ga_connector_info['firstclickmedium'] = firstClickMedium;
    }
    if (firstClickReferrer) {
      ga_connector_info['firstclickreferrer'] = Utility.removeHttp(firstClickReferrer);
    }
    if (firstClickSource) {
      ga_connector_info['firstclicksource'] = firstClickSource;
    }
    if (firstClickTerm) {
      ga_connector_info['firstclickterm'] = firstClickTerm;
    }
    if (lastClickCampaign) {
      ga_connector_info['lastclickcampaign'] = lastClickCampaign;
    }
    if (lastClickChannel) {
      ga_connector_info['lastclickchannel'] = lastClickChannel;
    }
    if (lastClickContent) {
      ga_connector_info['lastclickcontent'] = Utility.removeHttp(lastClickContent);
    }
    if (lastClickLandingPage) {
      ga_connector_info['lastclicklandingpage'] = Utility.removeHttp(lastClickLandingPage);
    }
    if (lastClickMedium) {
      ga_connector_info['lastclickmedium'] = lastClickMedium;
    }
    if (lastClickReferrer) {
      ga_connector_info['lastclickreferrer'] = Utility.removeHttp(lastClickReferrer);
    }
    if (lastClickSource) {
      ga_connector_info['lastclicksource'] = lastClickSource;
    }
    if (lastClickTerm) {
      ga_connector_info['lastclickterm'] = lastClickTerm;
    }
    if (gclid) {
      ga_connector_info['googleclickidentifier'] = gclid;
    }
    if (ipAddress) {
      ga_connector_info['ipaddress'] = ipAddress;
    }
    if (latitude) {
      ga_connector_info['latitude'] = latitude;
    }
    if (longitude) {
      ga_connector_info['longitude'] = longitude;
    }
    if (operatingSystem) {
      ga_connector_info['operatingsystem'] = operatingSystem;
    }
    if (gasessionid) {
      ga_connector_info['gasessionid'] = gasessionid;
    }

    return Object.keys(ga_connector_info).length > 0 ? ga_connector_info : '';
  }

  // Demurrage Configuration Services
  getDemurrageConfiguration(trackingShipmentId): Observable<Array<DemurrageConfigurations>> {
    return this.http.get<Array<DemurrageConfigurations>>(environment.rootUrl + `admin/notificationMetrics/${trackingShipmentId}`);
  }

  createDemurrageConfiguration(demurrageConfiguration: DemurrageConfiguration): Observable<NotificationMetric> {
    const payload = Utility.cleanupData(demurrageConfiguration);
    return this.http.post<NotificationMetric>(environment.rootUrl + `admin/notificationMetrics`, payload);
  }

  updateDemurrageConfiguration(
    notificationMetricId: number,
    demurrageConfiguration: DemurrageConfiguration
  ): Observable<NotificationMetric> {
    const payload = Utility.cleanupData(demurrageConfiguration);
    return this.http.put<NotificationMetric>(environment.rootUrl + `admin/notificationMetrics/${notificationMetricId}`, payload);
  }

  deleteDemurrageConfiguration(notificationMetricId: number): Observable<DeleteConfiguration> {
    return this.http.delete<DeleteConfiguration>(environment.rootUrl + `admin/notificationMetrics/${notificationMetricId}`);
  }
  //#endregion

  logEvent(event, eventDetail, hasUserInfo = false) {
    let data = {
      event,
      eventDetail,
    } as object;
    if (hasUserInfo) {
      data = {
        ...data,
        company_name: this.currentUserValue.organizationName, // 'Company Name'
        user_role: this.currentUserValue.accessRole, // 'ADMIN'
        user_id: this.currentUserValue.id, // 10000031
        user_state: this.currentUserValue.userEmail.endsWith('@dpworld.com') ? 'internal' : 'external',
        subscription_state: this.currentUserValue.accountType,
        organisation_id: this.currentUserValue.organizationId,
      };
    }
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(data);
  }
}
